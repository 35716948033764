import React, { Component } from 'react'; //different
// import './loginregis.css';
import Footer from './footer';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rewardlist: [],
            activePage: 1,
            isActive: false,
            categorylist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            logoimg: "",
            username: null,
            userid: null,
            showpass: true,
            point: 0,
            balance: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            linelink: "",
            amount: "",
            remark: "",
            name: "",
            password: "",
            left: false,
        }
        this.login = this.login.bind(this);
    }
    changepass = (e) => {
        e.preventDefault();
        this.setState({
            showpass: this.state.showpass ? false : true,
        });
    }
    login = (e) => {
        console.log(55665);
        e.preventDefault();
        let errors = this.validateLoginForm();
    }
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        await this.waitnotify();
    }
    waitnotify = async () => {
        setTimeout(() => { console.log('World!') }, 5000)
    }
    validateLoginForm = async (e) => {
        const { username, password } = this.state;
        console.log(username, password);
        await instance.post("/api/v1/userlogin", {
            userid: username,
            password: password,
            System: Systems,
            Function: 'validateLoginForm-navbar.js'
        })
            .then(async (res) => {
                console.log(res);
                if (res.data.status === 200) {
                    await localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        firstname: res.data.message.firstname,
                        lastname: res.data.message.lastname,
                        point: res.data.message.Point,
                        balance: res.databalance,
                        loginstate: true,
                    });
                    await this.notify("success", 'เข้าสุ่ระบบ กำลังเปลี่ยนเส้นทาง');
                    setTimeout(() => { window.location.href = '/home' }, 2000)
                }
                else if (res.data.status === 401) {
                    Swal.fire({
                        title: 'error!',
                        text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                }
                else {
                    Swal.fire({
                        title: 'error!',
                        text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                    //   Bonn.alert("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง")
                }

            });
    }
    async componentDidMount() {

        
        document.body.className = "text-white antialiased bg-gradient-to-b from-bg-f to-bg-t max-w-[414px] mx-auto";
        this.link = document.createElement('link');
        this.link.rel = 'stylesheet';
        this.link.href = './loginregis.css'; // Path to your CSS
        document.head.appendChild(this.link);


        this.link2 = document.createElement('link');
        this.link2.rel = 'stylesheet';
        this.link2.href = 'https://member.appufa.net/css/app.css?x=1734002654'; // Path to your CSS
        document.head.appendChild(this.link2);










        try {

            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {

                    const link3 = document.querySelector("link[rel*='icon']") || document.createElement('link');
                    link3.type = 'image/svg+xml';
                    link3.rel = 'icon';
                    link3.href = res.data.tblsetting[0].imglogo;
                    document.getElementsByTagName('head')[0].appendChild(link3);

                    const datas = res.data.message;
                    this.setState({
                        webname: res.data.tblsetting[0].Description,
                        logoimg: res.data.tblsetting[0].imglogo,
                        linelink: datas[1].value,
                        System: res.data.tblcatagoryx[0].System
                    });
                }
            });
        }
        catch (error) {
            //////console.log();
        }
    }
    onChange = event => {
        let nam;
        let val;
        if (event.target.name === undefined) {
            // //////console.log("undefined", event.target);
            nam = event.name;
            val = event.value;
        } else {
            // //////console.log("event", event.target);
            nam = event.target.name;
            val = event.target.value;
        }
        this.setState({ [nam]: val });

    };

    render() {

        return (
            <main className="min-h-screen bg-ufo-2 bg-no-repeat bg-[-350px_top] md:bg-[right_top] relative mx-auto p-4 pb-32 before:absolute before:bg-black/40 before:w-full before:h-full before:top-0 before:left-0 after:absolute after:bottom-6 after:left-0 after:w-full after:h-[175px] after:bg-town">
                <div className="flex flex-col items-center min-[340px]:py-28 relative z-10">
                    <div className="flex justify-center items-center rounded-md font-bold text-xl">
                        <img
                            src={this.state.logoimg}
                            style={{ width: 400 }}
                        />
                    </div>
                    <h1 className="section-text-ufa">ลิขสิทธิ์แท้ UFABET</h1>
                    <div style={{ marginTop: 30 }}>
                        <img
                            src="https://member.appufa.net/images/banner.jpg"
                            alt=""
                            className=""
                        />
                    </div>
                    <div className="w-full bg-gradient-to-br from-card-1-f to-card-1-t rounded-xl border border-white/40 p-4 p-6 section-dev-login">
                        <h1 className="text-xl font-medium text-highlight text-center">
                            เข้าสู่ระบบสมาชิก
                        </h1>
                        <div
                            className="w-full flex flex-col gap-4 mt-8"
                        >
                            <input
                                type="hidden"
                                name="_token"
                                defaultValue="ipqy59NdLiAXS3xK0xtQRvIEMdWhDrkpIDYxqHc9"
                            />{" "}
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <img
                                        src="https://member.appufa.net/images/icons/user.svg"
                                        alt="username"
                                    />
                                </div>
                                <input
                                    type="tel"
                                    className="border border-highlight/60 rounded-lg bg-input block w-full ps-10 p-2.5 focus:border-highlight/60"
                                    placeholder="เบอร์โทร"
                                    id="username"
                                    name="username"
                                    onChange={this.onChange}
                                    maxLength={10}
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    defaultValue=""
                                    required=""
                                    autoComplete="off"
                                />
                            </div>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <img
                                        src="https://member.appufa.net/images/icons/password.svg"
                                        alt="password"
                                        autoComplete="off"
                                    />
                                </div>
                                <input
                                    type={!this.state.showpass ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    onChange={this.onChange}
                                    minLength={6}
                                    defaultValue=""
                                    required=""
                                    className="inputstyle  border border-highlight/60 rounded-lg bg-input block w-full ps-10 p-2.5 focus:border-highlight/60"
                                    placeholder="รหัสผ่าน"
                                    autoComplete="off"
                                />
                                <button
                                    onClick={(e) => this.changepass(e)}
                                    type="button"
                                    id="show_password"
                                    className="absolute inset-y-0 end-0 flex items-center px-3"
                                >
                                    <img
                                        style={{ display: (!this.state.showpass) ? "none" : "" }}
                                        src="https://member.appufa.net/images/icons/eye-close.svg"
                                        alt="eye slash"
                                        className="eye-close"
                                    />
                                    <img
                                        style={{ display: (this.state.showpass) ? "none" : "" }}
                                        src="https://member.appufa.net/images/icons/eye-open.svg"
                                        alt="eye open"
                                        className="eye-open hidden"
                                    />
                                </button>
                            </div>
                            <button
                                onClick={(e) => {
                                    this.login(e)
                                }}
                                className="flex items-center justify-center h-12 w-full bg-gradient-to-br from-button-f from-40% to-button-t rounded-md font-semibold"

                            >
                                เข้าสู่ระบบ
                            </button>
                            <div className="flex items-center justify-center gap-2 text-md text-gray">
                                <a
                                    href="/register"
                                     role="button"
                                    className="text-highlight"
                                >
                                    สมัครสมาชิก
                                </a>
                                <span>/</span>
                                <a href={this.state.linelink} target="_blank" className="">
                                    ติดต่อเรา
                                </a>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 30, width: 190 }}>
                        <img
                            src="https://member.appufa.net/images/ufabetpng.png"
                            alt=""
                            className=""
                        />
                    </div>
                </div>
            </main>

        );
    }
}
export default App;
