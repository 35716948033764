import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import { json } from 'react-router-dom';
import { LoopTwoTone } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            stateopterror: "",
            stateregis: 1,
            showpass: true,
            lang: window.localStorage.getItem("lang"),
            bankselect: 0,
            trueaccount: true,
            checkname: false,
            username: null,
            fname: "",
            logoimg: "",
            checkboxacc: 0,
            userid: null,
            phoneNumber: "",
            userrecomend: "",
            linelink: "",
            balance: 0,
            mainacc: null,
            dropdownVisible: false, // ควบคุมการแสดง dropdown
            selectedBank: {}, // เก็บข้อมูลธนาคารที่เลือก
            banks: [
                { id: 5, value: "SCB", name: "ไทยพาณิชย์", logo: "https://member.appufa.net/images/bank/scb.svg?v=1" },
                { id: 6, value: "KBNK", name: "กสิกรไทย", logo: "https://member.appufa.net/images/bank/kbank.svg?v=1" },
                { id: 7, value: "BBL", name: "กรุงเทพ", logo: "https://member.appufa.net/images/bank/bbl.svg?v=1" },
                { id: 8, value: "BAY", name: "กรุงศรีอยุธยา", logo: "https://member.appufa.net/images/bank/bay.svg?v=1" },
                { id: 9, value: "KKB", name: "เกียรตินาคิน", logo: "https://member.appufa.net/images/bank/kiatnakin.svg?v=1" },
                { id: 11, value: "TMB", name: "ทหารไทยธนชาต", logo: "https://member.appufa.net/images/bank/ttb.svg?v=1" },
                { id: 12, value: "TBNK", name: "ธนชาต", logo: "https://member.appufa.net/images/bank/cimb.svg?v=1" },
                { id: 14, value: "KTB", name: "กรุงไทย", logo: "https://member.appufa.net/images/bank/ktb.svg?v=1" },
                { id: 15, value: "GSBA", name: "ออมสิน", logo: "https://member.appufa.net/images/bank/gsb.svg?v=1" },
                { id: 16, value: "BAAC", name: "เพื่อการเกษตรและสหกรณ์การเกษตร", logo: "https://member.appufa.net/images/bank/baac.svg?v=1" },
                { id: 53, value: "GHBA", name: "อาคารสงเคราะห์", logo: "https://member.appufa.net/images/bank/ghb.png?v=1" },
                { id: 99, value: "TRUE", name: "ทรูวอลเลท", logo: "https://cdn.javisx.com/image/ca805e12-cad1-41b7-84bf-3fb2c637143f.png" }
            ]

        }
        this.forgetotpandregister = this.forgetotpandregister.bind(this);
    }
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
    confirmdeposit = async (e, num) => {

        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;

        await instance.post("/api/v1/getredeempromotion", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    proderedeem: res.data.message.tblpromotionredeems,
                });

            }
        }).catch(async (err) => {
        });

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                ////////console.log(depositstate, num);
                if (num === 0) {
                    if (token != null) {
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            ////////console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    radiocheck(input) {
        console.log(input);
        this.setState({
            bankselect: input
        });
        if (input == 99) {
            this.setState({
                trueaccount: false
            });
        }
        else {
            this.setState({
                trueaccount: true,
                fname: ""
            });
        }
    }
    forgetotpandregister = async (type) => {
        const { phoneNumber, otpphoneforget, selectedBank, otpphone, accnumbertxt } = this.state;
        var recheck = false;
        if (type === 4) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 5) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphoneforget,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 6) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 7) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphone,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 10) {
            return await instance.post("/api/v1/resetpassword", {
                accountnumber: accnumbertxt,
                bankid: selectedBank.id,
                System: Systems,
                type: type
            }).then(async (res) => {
                return recheck = res.data.message;

            });
        }
        return recheck
    }
    toggleDropdown = () => {
        this.setState((prevState) => ({ dropdownVisible: !prevState.dropdownVisible }));
    };

    // เลือกธนาคาร
    handleSelectBank = (bank) => {
        this.setState({ selectedBank: bank, dropdownVisible: false });
        console.log("ธนาคารที่เลือก:", bank); // สามารถนำไปใช้งานต่อได้
    };

    nextstep = async (e, num) => {
        e.preventDefault();
        const { stateregis, selectedBank, password, bankselect, passwordcf, phoneNumber, checkboxacc, fname, otpphone, accnumbertxt } = this.state
        console.log(5565656, num, selectedBank.id, accnumbertxt);
        if (num === 1) {
            const check = await this.forgetotpandregister(6);

            console.log(phoneNumber.length === 10 && check.checked)
            if (phoneNumber.length === 10 && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else {
                if (phoneNumber.length !== 10) {
                    await this.notify("warning", 'คุณใส่เบอร์ผิดพลาด');
                }
                else if (!check.checked && check.status === 201) {
                    await this.notify("warning", 'เบอร์นี้มีคนใช้แล้ว');
                }
                else {
                    await this.notify("warning", 'คุณทำรายการถี่เกินไป โปรดรอซักครู่');
                    this.setState({
                        stateopterror: "คุณทำรายการถี่เกินไป โปรดรอซักครู่"
                    });
                }
            }
        }
        else if (num === 2) {

            if (password !== passwordcf) {
                await this.notify("warning", 'คุณไส่พาสเวิดไม่ตรงกัน');
            }
            else {
                this.setState({
                    stateregis: 3
                });
            }

        }
        else if (num === 3) {

            if (accnumbertxt !== "" && accnumbertxt !== undefined && Object.keys(selectedBank).length !== 0) {
                console.log(num, "11");
                this.setState({
                    stateregis: 2
                });
            }
            else if (selectedBank == {}) {
                console.log(num, "22");
                await this.notify("warning", 'กรุณาเลือกธนาคาร หรือใส่ข้อมูลให้ครบถ้วน');
            }
            else if (fname === "") {
                console.log(num, "33");
                await this.notify("warning", 'กรุณาณาใส่เลขบัญชี');
            }
            else {
                await this.notify("warning", 'กรุณาเลือกธนาคาร หรือใส่ข้อมูลให้ครบถ้วน');
                //////console.log(2, stateregis, checkboxacc);
            }
        }
        else if (num === 5) {
            var check = await this.forgetotpandregister(7);
            if (otpphone !== "" && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else if (otpphone === "" || otpphone.length < 4) {
                await this.notify("warning", 'คุณใส่ตัวเลขไม่ครบ');
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }

            else {
                await this.notify("warning", 'รหัสผิดพลาด');
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }



        }
        else if (num === 11) {

            console.log(1, stateregis, num);

            this.setState({
                stateregis: 1
            });
        }
        else if (num === 22) {
            //////console.log(1, stateregis, num);
            this.setState({
                stateregis: 2
            });
        }
        else if (num === 25) {
            if (accnumbertxt === "" || typeof accnumbertxt == "undefined") {
                var title = 'error'
                var text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
                var object = { title, text }
                this.modalerror(object);

            }
            else if (accnumbertxt.length < 10) {
                var title = 'error'
                var text = 'คุณใส่เลขบัญชีไม่ถูกต้อง'
                var object = { title, text }
                this.modalerror(object);
            }
            else if (Object.keys(selectedBank).length === 0) {
                var title = 'error'
                var text = 'คุณยังไม่ได้เลือกธนาคาร'
                var object = { title, text }
                this.modalerror(object);
            }
            else {
                this.setState({
                    loading: true,
                });
                if (fname === '' && selectedBank.id != 99) {
                    console.log(2)
                    let check = await this.forgetotpandregister(10)
                    if (check.checked) {
                        console.log(2.1)
                        if (check.verifications.status == 0) {
                            console.log(2.2)
                            this.setState({
                                stateregis: 2,
                                trueaccount: false,
                            });
                        }
                        else if (check.verifications.status == 1) {
                            console.log(2.3)
                            this.setState({
                                fname: check.verifications.name,
                                checkname: true,
                                stateregis: 2
                            });
                            //  await this.validatergisterForm(e, check.verifications.name);
                        }
                    }
                    else {
                        console.log(2.4)
                        var title = 'error'
                        var text = 'เลขบัญชีไม่ถูกต้อง'
                        var object = { title, text }
                        this.modalerror(object);
                    }
                }
                else {
                    console.log(2.5)
                    this.setState({
                        trueaccount: false,
                        stateregis: 2
                    });
                    //  await this.validatergisterForm(e, fname)
                }
                console.log(2.6)
                this.setState({
                    loading: false,
                });
                //console.log(302, check.verifications);
            }



        }
        else if (num === 26) {
            const check = await this.forgetotpandregister(6);
            if (phoneNumber.length !== 10 && !check.checked) {
                if (phoneNumber.length !== 10) {
                    await this.notify("warning", 'คุณใส่เบอร์ผิดพลาด');
                }
                else if (!check.checked && check.status === 201) {
                    await this.notify("warning", 'เบอร์นี้มีคนใช้แล้ว');
                }
                else {
                    await this.notify("warning", 'คุณทำรายการถี่เกินไป โปรดรอซักครู่');
                    this.setState({
                        stateopterror: "คุณทำรายการถี่เกินไป โปรดรอซักครู่"
                    });
                }
            }
            else if (password?.length < 6) {
                await this.notify("warning", 'คุณไส่พาสเวิดน้อยกว่า 6 ตัว');
            }
            else {
                this.setState({
                    loading: true,
                });
                if (fname !== '') {
                    console.log(2.5)
                    await this.validatergisterForm(e, fname)
                }
                else {
                    await this.notify("warning", 'ทำรายการไม่สำเร็จ');
                }
                this.setState({
                    loading: false,
                });
                //console.log(302, check.verifications);
            }



        }
    };

    onChangex = event => {
        // console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === "otpphone") {
                document.getElementById("otpphone").style = "";
                document.getElementById("checkotp").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("otpphoneforget").style = "";
                document.getElementById("checkotpforget").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("phoneNumber").style = "";
                document.getElementById("checkotpforgetphonenormal").style.display = "none";
            }

            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };
    async modalerror(object) {
        // await Swal.fire({
        //     title: object.title + "!",
        //     text: object.text,
        //     icon: object.title,
        //     confirmButtonText: 'ตกลง'
        // })
        await this.notify(object.title, object.text);
    }
    validatergisterForm = async (e, fname) => {
        e.preventDefault();
        const { phoneNumber, selectedBank, password, passwordcf, bankselect, accnumbertxt } = this.state;
        this.setState({
            stateregis: 4
        });
        if (password.length > 15) {
            var title = 'error'
            var text = 'พาสเวิดสูงสุดไม่เกิน 15 ตัว'
            var object = { title, text }
            this.modalerror(object);
        }
        else if (password.length < 6) {
            var title = 'error'
            var text = 'พาสเวิดต่ำไม่เกิน 15 ตัว'
            var object = { title, text }
            this.modalerror(object);
        }
        else {

            await instance.post("/api/v1/register", {
                User_id: phoneNumber,
                name: fname,
                password: password,
                accbank: selectedBank.id,
                accno: accnumbertxt,
                System: Systems,
                Useradvise: this.state.userrecomend
            }).then(async (res) => {
                console.log(res.data);
                if (res.data.status === 200) {
                    this.setState({ stateregis: 3 });
                    await instance.post("/api/v1/userlogin", {
                        userid: phoneNumber,
                        password: password,
                        System: Systems,
                        Function: 'validateLoginForm-navbar.js'
                    }).then((res) => {
                        console.log(res.data);
                        if (res.data.status === 200) {
                            this.setState({
                                stateregis: 3
                            });
                            localStorage.setItem('auth_token', res.data.token);
                            setInterval(() =>
                                window.location.href = '/home'
                                , 1500);

                        }
                        else {
                            this.setState({
                                stateregis: 2
                            });
                            var title = 'error'
                            var text = 'โปรดตรวจสอบข้อมูลอีกครั้ง'
                            var object = { title, text }
                            this.modalerror(object);
                        }
                    });
                }
                else if (res.data.status === 203) {
                    this.setState({
                        stateregis: 2
                    });
                    var title = 'error'
                    var text = 'เลขบัญชี นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.modalerror(object);
                }
                else if (res.data.status === 202) {
                    this.setState({
                        stateregis: 2
                    });
                    var title = 'error'
                    var text = 'Username นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.modalerror(object);
                }
                else {
                    this.setState({
                        stateregis: 2
                    });
                    var title = 'error'
                    var text = 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
                    var object = { title, text }
                    this.modalerror(object);
                }
            });
        }


    };
    changepass = (e) => {
        e.preventDefault();
        this.setState({
            showpass: this.state.showpass ? false : true,
        });
    }
    async saveclickaff(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                this.setState({
                    userrecomend: res.data.username,
                    link: linkid
                });
                var username = res.data.username
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                var username = res.data.username
                this.setState({
                    userrecomend: username,
                });
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async componentDidMount() {
        // var token = localStorage.getItem('auth_token');
        // if (token != null) {
        //     localStorage.removeItem('auth_token', '')
        // }
        document.body.className = "text-white antialiased bg-gradient-to-b from-bg-f to-bg-t max-w-[414px] mx-auto";

        this.link = document.createElement('link');
        this.link.rel = 'stylesheet';
        this.link.href = './loginregis.css'; // Path to your CSS
        document.head.appendChild(this.link);


        this.link2 = document.createElement('link');
        this.link2.rel = 'stylesheet';
        this.link2.href = 'https://member.appufa.net/css/app.css?x=1734002654'; // Path to your CSS
        document.head.appendChild(this.link2);





        const search = window.location.href;
        var test = search.split("aff=")[1]
        if (test != undefined) {
            this.saveclickaff(test.split("&")[0]);
        }
        var params = search.split("reg=")[1]
        if (params != undefined) {
            this.saveclick(params);
            this.setState({
                userrecomend: params.split("&")[0],
            });
        }

        var tokenf = localStorage.getItem('aff');
        if (tokenf != null) {
            var codev = JSON.parse(tokenf)
            //   console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }

        var tokenx = localStorage.getItem('register');
        if (tokenx != null) {
            var codev = JSON.parse(tokenx)
            // console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }


        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {

                const datas = res.data.message;
                var min = res.data.tblcatagoryx[0].limitdeposit;
                var max = res.data.tblcatagoryx[0].maxdeposit;
                var limit = { min, max }
                const link3 = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link3.type = 'image/svg+xml';
                link3.rel = 'icon';
                link3.href = res.data.tblsetting[0].imglogo;
                document.getElementsByTagName('head')[0].appendChild(link3);
                this.setState({
                    webname: res.data.tblsetting[0].Description,
                    logoimg: res.data.tblsetting[0].imglogo,
                    linelink: datas[1].value,
                    System: res.data.tblcatagoryx[0].System,
                    limit: limit
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        //  this.nameInput.focus();
    }





    render() {

        const { dropdownVisible, selectedBank, banks, stateregis } = this.state;

        //console.log(prodetail.length)
        return (
            <>
                <main className="min-h-screen bg-ufo-2 bg-no-repeat bg-[-350px_top] md:bg-[right_top] relative mx-auto p-4 pb-32 before:absolute before:bg-black/40 before:w-full before:h-full before:top-0 before:left-0 after:absolute after:bottom-6 after:left-0 after:w-full after:h-[175px] after:bg-town">
                    <div className="flex flex-col items-center min-[340px]:py-28 relative z-10">
                        <div className="flex justify-center items-center rounded-md font-bold text-xl">
                            <img
                                src={this.state.logoimg}
                                style={{ width: 400 }}
                            />
                        </div>
                        <h1 className="text-2xl font-medium text-highlight text-center mt-8">
                            สมัครสมาชิก
                        </h1>
                        <p className="text-sm font-normal">สมัครง่ายๆ เพียง 3 ขั้นตอน</p>
                        <div className="mt-8 px-6 w-full relative text-center" id="register_form">
                            <ul
                                id="progressbar"
                                className="overflow-hidden flex items-center justify-between gap-4 mb-8"
                            >
                                <li className="relative text-center text-sm basis-1/3 text-highlight opacity-100">
                                    <div className="w-12 h-12 rounded-full mb-4 mx-auto bg-gradient-to-b from-bg-f from-30% to-bg-t flex items-center justify-center border border-highlight text-xl text-highlight">
                                        1
                                    </div>
                                    บัญชีธนาคาร
                                </li>
                                <li className={`relative text-center text-sm basis-1/3  before:absolute before:top-[25px] before:-left-[35px] before:w-[55px] before:h-[1px] before:bg-white/50 ${stateregis === 2 ? 'text-highlight opacity-100' : ' opacity-40'}`} >
                                    <div className="w-12 h-12 rounded-full mb-4 mx-auto bg-gradient-to-b from-bg-f from-30% to-bg-t flex items-center justify-center border border-highlight text-xl text-highlight">
                                        2
                                    </div>
                                    ข้อมูลสมาชิก
                                </li>
                                <li className={`relative text-center text-sm basis-1/3  before:absolute before:top-[25px] before:-left-[35px] before:w-[55px] before:h-[1px] before:bg-white/50 ${stateregis === 3 ? 'text-highlight opacity-100' : ' opacity-40'}`} >
                                    <div className="w-12 h-12 rounded-full mb-4 mx-auto bg-gradient-to-b from-bg-f from-30% to-bg-t flex items-center justify-center border border-highlight text-xl text-highlight">
                                        3
                                    </div>
                                    สำเร็จ
                                </li>
                            </ul>
                            <input type="text" id="bankCode" hidden="true" />
                            <fieldset className={`relative space-y-4${stateregis === 1 ? '' : ' hidden'}`} >

                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <img
                                            src="https://member.appufa.net/images/icons/bank.svg"
                                            alt="bank"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        value={selectedBank.name || ""}
                                        placeholder="เลือกธนาคาร"
                                        readOnly
                                        className="border border-highlight/60 rounded-lg bg-input block w-full ps-10 p-2.5 focus:border-highlight/60"
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 end-0 px-3"
                                        onClick={this.toggleDropdown}
                                    >
                                        <img
                                            src="https://member.appufa.net/images/icons/arrow-dropdown.svg"
                                            alt="arrow down"
                                            className={`float-right ${dropdownVisible ? "rotate-180" : "rotate-0"}`}
                                        />
                                    </button>

                                    {/* Dropdown */}
                                    {dropdownVisible && (
                                        <div className="z-50 bg-[#111A33] rounded-xl w-full absolute mt-2">
                                            <ul className="p-4 grid grid-cols-3 gap-4 max-h-[250px] overflow-y-scroll">
                                                {banks.map((bank) => (
                                                    <li
                                                        key={bank.value}
                                                        className="text-center text-sm cursor-pointer"
                                                        onClick={() => this.handleSelectBank(bank)}
                                                    >
                                                        <img
                                                            src={bank.logo}
                                                            alt={bank.name}
                                                            className="w-2/4 block mx-auto mb-2"
                                                        />
                                                        {bank.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <img
                                            src="https://member.appufa.net/images/icons/bank.svg"
                                            alt="bank"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        inputMode="numeric"
                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} // ป้องกันพิมพ์ตัวอักษร
                                        maxLength={15}
                                        onChange={this.onChangex}
                                        id="accnumbertxt"
                                        name="accnumbertxt"
                                        defaultValue=""
                                        className="border border-highlight/60 rounded-lg bg-input block w-full ps-10 p-2.5 focus:border-highlight/60"
                                        autoComplete="off"
                                        placeholder="เลขที่บัญชี"
                                    />

                                </div>
                                <button
                                    onClick={(e) => {
                                        this.nextstep(e, 25)
                                    }}
                                    type="button"
                                    className="next flex items-center justify-center h-12 w-full bg-gradient-to-br from-button-f from-40% to-button-t rounded-md font-semibold"
                                >
                                    ถัดไป
                                </button>
                                <div className="flex items-center justify-center gap-2 mt-4 text-md text-gray">
                                    <a
                                        href={this.state.linelink} target="_blank"
                                        onclick="window.open('https://lin.ee/EonULTV', '_blank');"
                                        className="text-highlight"
                                    >
                                        ติดต่อเรา
                                    </a>
                                    <span>/</span>
                                    <a href="/login" role="button" className="">
                                        เข้าสู่ระบบ
                                    </a>
                                </div>
                            </fieldset>
                            <fieldset className={`relative space-y-4 ${stateregis === 2 ? '' : ' hidden'}`}>
                                <div className="w-full bg-gradient-to-br from-card-1-f to-card-1-t rounded-xl border border-white/40 p-4 drop-shadow-[0_4px_30px_#ffffff33] to-50%">
                                    <div className="flex items-center gap-4">
                                        <img id="bankIcon" className="w-[50px] h-[50px]" src={selectedBank.logo} />
                                        <div className="w-full text-start text-sm text-gray space-y-1">
                                            {/* <div>
                                                ชื่อบัญชี : <span id="accountName" />
                                            </div> */}
                                            <div>
                                                เลขที่บัญชี : <span id="accountNumber" > {this.state.accnumbertxt}</span >
                                            </div>
                                            <div>
                                                ธนาคาร : <span id="bankName" >{selectedBank.name}  </span >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative w-full" style={{ display: this.state.trueaccount && !this.state.checkname ? "none" : "" }}>
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <img
                                            src="https://member.appufa.net/images/icons/phone.svg"
                                            alt="username"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        onKeyPress={(e) => {
                                            if (!/[A-Za-zก-๙เ-๋็่-๋\s]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={this.onChangex}
                                        id="fname"
                                        value={this.state.fname}
                                        name="fname"
                                        defaultValue=""
                                        className="border border-highlight/60 rounded-lg bg-input block w-full ps-10 p-2.5 focus:border-highlight/60"
                                        autoComplete="off"
                                        placeholder="ชื่อบัญชี"
                                        readOnly={this.state.checkname}
                                    />

                                    <span id="phoneLengthMessage" style={{ color: "red" }} />
                                </div>
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <img
                                            src="https://member.appufa.net/images/icons/phone.svg"
                                            alt="username"
                                        />
                                    </div>
                                    <input
                                        type="tel"
                                        maxLength={10}
                                        inputMode="numeric"
                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} // ป้องกันพิมพ์ตัวอักษร
                                        onChange={this.onChangex}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        defaultValue=""
                                        className="border border-highlight/60 rounded-lg bg-input block w-full ps-10 p-2.5 focus:border-highlight/60"
                                        autoComplete="off"
                                        placeholder="เบอร์โทรศัพท์ 10 หลัก"
                                        required
                                    />
                                    <span id="phoneLengthMessage" style={{ color: "red" }} />
                                </div>
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <img
                                            src="https://member.appufa.net/images/icons/password.svg"
                                            alt="password"
                                        />
                                    </div>
                                    <input
                                        onChange={this.onChangex}
                                        type={!this.state.showpass ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        minLength={6}
                                        required=""
                                        className="border border-highlight/60 rounded-lg bg-input block w-full ps-10 p-2.5 focus:border-highlight/60"
                                        placeholder="รหัสผ่านอย่างน้อย 6 ตัว"
                                        autoComplete="off"
                                    />
                                    <button
                                        type="button"
                                        id="show_password"
                                        onClick={(e) => this.changepass(e)}
                                        className="absolute inset-y-0 end-0 flex items-center px-3"
                                    >
                                        <img
                                            style={{ display: (!this.state.showpass) ? "none" : "" }}
                                            src="https://member.appufa.net/images/icons/eye-close.svg"
                                            alt="eye slash"
                                            className="eye-close"
                                        />
                                        <img
                                            style={{ display: (this.state.showpass) ? "none" : "" }}
                                            src="https://member.appufa.net/images/icons/eye-open.svg"
                                            alt="eye open"
                                            className="eye-open hidden"
                                        />
                                    </button>
                                </div>
                                <span id="passwordLengthMessage" style={{ color: "red" }} />
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <img
                                            src="https://member.appufa.net/images/icons/line.svg"
                                            alt="line"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        name="line"
                                        id="line"
                                        maxLength={50}
                                        className="border border-highlight/60 rounded-lg bg-input block w-full ps-10 p-2.5 focus:border-highlight/60"
                                        placeholder="Line ID (สำหรับติดต่อ)"
                                        required=""
                                        autoComplete="off"
                                    />
                                    <span id="lineHelp" style={{ color: "red" }} />
                                    <input
                                        type="text"
                                        style={{ display: "none" }}
                                        defaultValue="StEOamz"
                                        name="referral_code"
                                        id="referral_code"
                                    />
                                    <input
                                        type="text"
                                        style={{ display: "none" }}
                                        defaultValue=""
                                        name="affID"
                                        id="aff_code"
                                    />
                                </div>
                                <button
                                    onClick={(e) => {
                                        this.nextstep(e, 26)
                                    }}
                                    type="submit"
                                    className="finish flex items-center justify-center h-12 w-full bg-gradient-to-br from-button-f from-40% to-button-t rounded-md font-semibold"
                                >
                                    สมัครสมาชิก
                                </button>
                                <div className="flex items-center justify-center gap-2 mt-4 text-md text-gray">
                                    <a onClick={(e) => {
                                        this.setState({
                                            stateregis: 1,
                                            fname: ''
                                        });
                                    }} role="button" className="">
                                        ย้อนกลับ
                                    </a>
                                </div>
                            </fieldset>
                            <fieldset className={`relative py-10 ${stateregis === 4 ? '' : ' hidden'}`}>
                                <div className="flex flex-col items-center justify-center gap-4">
                                    <img
                                        src="https://member.appufa.net/images/icons/check.png"
                                        alt="success"
                                    />
                                    <h2 className="text-highlight text-2xl">กำลังตรวจสอบโปรดรอซักครู่</h2>
                                    <ul className="inline-flex gap-2">
                                        <li className="loading-dot" />
                                        <li className="loading-dot" />
                                        <li className="loading-dot" />
                                        <li className="loading-dot" />
                                    </ul>
                                </div>

                            </fieldset>
                            <fieldset className={`relative py-10 ${stateregis === 3 ? '' : ' hidden'}`}>
                                <div className="flex flex-col items-center justify-center gap-4">
                                    <img
                                        src="https://member.appufa.net/images/icons/check.png"
                                        alt="success"
                                    />
                                    <h2 className="text-highlight text-2xl">สมัครสมาชิกสำเร็จ!</h2>
                                    <h2 className="text-highlight text-1xl">โปรดรอ กำลังเปลี่ยนเส้นทาง!</h2>
                                    <ul className="inline-flex gap-2">
                                        <li className="loading-dot" />
                                        <li className="loading-dot" />
                                        <li className="loading-dot" />
                                        <li className="loading-dot" />
                                    </ul>
                                </div>

                            </fieldset>
                        </div>
                    </div>
                </main>

            </>
        );
    }
}
export default App;
